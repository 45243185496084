import { Capacitor } from '@capacitor/core';
import { Injectable, inject } from '@angular/core';
import { Toast } from '@capacitor/toast';
import { ToastController } from '@ionic/angular/standalone';
import { ToastOptions } from '@interfaces/toast-options.interface';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
/*
 * ToastService offers access to native toast notifications
 */
export class ToastService {
  private readonly toastController: ToastController = inject(ToastController);
  private readonly translate: TranslateService = inject(TranslateService);
  private lastToastText: string | undefined = undefined;
  private lastToastTime: number | undefined = undefined;
  private toastId: string | undefined = undefined;

  public async showToast(options: ToastOptions): Promise<void> {
    // If not currently showing a toast, show the next one
    if (this.toastId) {
      await this.toastController.dismiss();
    }
    await this.showNextToast(options);
  }

  private async showNextToast(options: ToastOptions): Promise<void> {
    const currentText = this.translate.instant(options.text, options.textInterpolations);
    const currentTime = Date.now();

    // If the last toast text is the same as the current text and it was shown less than 15 seconds ago, don't show the toast
    if (this.lastToastText === currentText && this.lastToastTime && currentTime - this.lastToastTime < 15000) {
      return;
    }

    this.lastToastText = currentText;
    this.lastToastTime = currentTime;

    if (Capacitor.isNativePlatform()) {
      await Toast.show({
        text: currentText,
        duration: options.duration || 'long',
        position: options.position || 'bottom'
      });
    } else {
      const toast = await this.toastController.create({
        color: options.color || 'danger',
        message: currentText,
        duration: 5000,
        position: 'top'
      });
      this.toastId = toast.id;
      await toast.present();
    }
  }
}
