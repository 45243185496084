import { ActionSheet, ShowActionsOptions, ShowActionsResult } from '@capacitor/action-sheet';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
/*
 * ActionSheetService offers access to native action sheets
 */
export class ActionSheetService {
  public async presentActionSheet(options: ShowActionsOptions): Promise<ShowActionsResult> {
    const result = await ActionSheet.showActions(options);
    return result;
  }
}
