import { ActionSheetController, LoadingController, ModalController } from '@ionic/angular/standalone';
import { ActionSheetOptions } from '@interfaces/actionsheet-options.interface';
import { ActionSheetService } from '@services/action-sheet/action-sheet.service';
import { DialogService } from '@services/dialog/dialog.service';
import { Environment } from '@app/interfaces/environment.interface';
import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from '@services/storage/storage.service';
import { ToastService } from '@services/toast/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@environment/environment';

@Injectable({
  providedIn: 'root'
})

/*
 * Base class for all other services. Sets some default values for all child services.
 * Also holds base configuration, depending on environment.
 */
export class BaseService {
  // declarations of fields
  public env: Environment = environment;
  public readonly actionSheetService: ActionSheetService = inject(ActionSheetService);
  public readonly actionSheetController: ActionSheetController = inject(ActionSheetController);
  public readonly dialogService: DialogService = inject(DialogService);
  public readonly loadingController: LoadingController = inject(LoadingController);
  public readonly modalController: ModalController = inject(ModalController);
  public readonly router: Router = inject(Router);
  public readonly storageService: StorageService = inject(StorageService);
  public readonly toastService: ToastService = inject(ToastService);
  public readonly translate: TranslateService = inject(TranslateService);

  /*
   * Pass key and retrieve specific environment property.
   */
  public getEnv(key: string): string | boolean | { [key: string]: string } {
    return this.env[key as keyof Environment];
  }

  /*
   * A method to display alert
   */
  public async presentActionSheet(options: ActionSheetOptions): Promise<void> {
    const defaultOptions: ActionSheetOptions = {
      buttons: []
    };

    if (options.buttons) {
      defaultOptions.buttons = options.buttons;
      defaultOptions.buttons.push({
        text: this.translate.instant('buttons_cancel'),
        role: 'cancel'
      });
    }

    defaultOptions.header = options.header
      ? this.translate.instant(options.header)
      : this.translate.instant('buttons_select');
    defaultOptions.cssClass = options.cssClass ? options.cssClass : '';

    const actionSheet = await this.actionSheetController.create(defaultOptions as any);

    await actionSheet.present();
  }

  /*
   * Used to construct the url for the custom router navigation
   */
  public getRoute(route: string, params: { [key: string]: string }): string {
    if (params && Object.keys(params).length > 0) {
      route = route.replace(/\${(\w+)}/g, (_, v) => {
        return params[v];
      });
    }
    return route;
  }
}
