export const environment = {
  loggerMail: 'stop@webpunks.at',
  mapsUrl: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyAjc2ibFzzu5-JgqrZmrSU-utgGlJhddpc',
  firebase: {
    apiKey: 'AIzaSyD9AXneOnKcH0r4GqhMpimmXp8gEsWprOE',
    appId: '1:253562112393:web:2f9e2cb15a31e83b4af958',
    authDomain: 'stop-send-and-defend.firebaseapp.com',
    defaultRegion: 'europe-west3',
    httpEndpoint: 'https://europe-west3-stop-send-and-defend.cloudfunctions.net/app',
    measurementId: 'G-GLHZ377X7X',
    messagingSenderId: '253562112393',
    projectId: 'stop-send-and-defend',
    storageBucket: 'stop-send-and-defend.appspot.com'
  },
  production: true,
  useEmulators: false,
  version: '1.0.1 (1023)'
};
