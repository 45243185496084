/* eslint-disable no-unused-vars */
import { AlertController } from '@ionic/angular/standalone';
import { AlertOptions, ConfirmOptions, Dialog, PromptOptions, PromptResult } from '@capacitor/dialog';
import { Capacitor } from '@capacitor/core';
import { Injectable, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})

/*
 * DialogService offers access to native dialogs. On web Ionic alert controller is used.
 */
export class DialogService {
  private readonly alertController: AlertController = inject(AlertController);
  private readonly translate: TranslateService = inject(TranslateService);

  /*
   * A method to display alert dialog
   */
  public async showAlert(options: AlertOptions): Promise<void> {
    if (Capacitor.isNativePlatform()) {
      return await Dialog.alert(options);
    } else {
      let resolveFunction: () => void;
      const alert = await this.alertController.create({
        backdropDismiss: false,
        header: options.title,
        message: options.message,
        buttons: [
          {
            text: 'OK',
            handler: () => resolveFunction()
          }
        ]
      });
      await alert.present();
      return new Promise<void>(resolve => {
        resolveFunction = resolve;
      });
    }
  }

  /*
   * A method to display confirm dialog
   */
  public async showConfirm(options: ConfirmOptions): Promise<boolean> {
    if (Capacitor.isNativePlatform()) {
      const { value } = await Dialog.confirm({
        cancelButtonTitle: options.cancelButtonTitle || this.translate.instant('buttons_cancel'),
        okButtonTitle: options.okButtonTitle || 'OK',
        ...options
      });
      return value;
    } else {
      let resolveFunction: (confirm: boolean) => void;
      if (options.title && options.message) {
        const alert = await this.alertController.create({
          backdropDismiss: false,
          header: this.translate.instant(options.title),
          message: this.translate.instant(options.message),
          buttons: [
            {
              text: options.cancelButtonTitle || this.translate.instant('buttons_cancel'),
              role: 'cancel',
              handler: () => resolveFunction(false)
            },
            {
              text: options.okButtonTitle || 'OK',
              handler: () => resolveFunction(true)
            }
          ]
        });
        await alert.present();
      }
      return new Promise<boolean>(resolve => {
        resolveFunction = resolve;
      });
    }
  }

  /*
   * A method to display prompt dialog
   */
  public async showPrompt(options: PromptOptions): Promise<PromptResult | void> {
    if (options.title && options.message) {
      const { value, cancelled } = await Dialog.prompt({
        title: this.translate.instant(options.title),
        message: this.translate.instant(options.message)
      });
      return { value, cancelled };
    }
  }
}
